//http://185.182.219.47:8000/
import axios from "axios";

let baseURL = `http://185.111.106.120:8001/`


export default axios.create({
    baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*',
        // "Access-Control-Allow-Methods": "POST,  OPTIONS, DELETE, PUT",
        // enableFullCORS: true

    },
    // responseType: 'blob',
    withCredentials: false

});