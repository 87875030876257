<template>
  <button @click="$emit('onBtnClick')" :class="['btn', `btn--${variant}`]">
    <slot> Подробнее </slot>
    <base-icon
      name="arrow-right"
      width="16"
      height="16"
      class="icon"
      v-show="variant === 'primary'"
    />
  </button>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  components: { BaseIcon },
  props: {
    variant: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "outlined", "primary", "footer", "filter"].includes(
          value
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
  transition: all 1s ease;
  display: flex;
  align-items: center;

  &--default {
    max-width: max-content;
    background: #0070d7;
    border-radius: 12px;
    line-height: 120%;
    padding: 12px 16px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    width: 100%;
    white-space: nowrap;

    &:hover:not(:disabled) {
      background: #0861b2;
    }
    &:active {
      background: #0861b2;
    }
    &:disabled {
      background: #dddeea;
      cursor: not-allowed;
    }
  }
  &--outlined {
    max-width: max-content;
    background: #ffffff;
    border: 1px solid #0070d7;
    border-radius: 12px;
    line-height: 120%;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #0070d7;
    width: 100%;
    white-space: nowrap;

    &:hover:not(:disabled) {
      background: #f4faff;
      border: 1px solid #0861b3;

      color: #0861b3;
    }
    &:active {
      background: #f4faff;
      border: 1px solid #0861b3;

      color: #0861b3;
    }
    &:disabled {
      background: #f4faff;
      color: #c5cdd5;
      border: none;
      cursor: not-allowed;
    }
  }
  &--primary {
    max-width: max-content;
    background: none;
    border: none;
    line-height: 120%;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #0070d7;
    width: 100%;
    white-space: nowrap;

    &:hover:not(:disabled) {
      color: #0861b3;
    }
    &:active {
      color: #0861b3;
    }
    &:disabled {
      color: #c5cdd5;
      cursor: not-allowed;
    }
  }
  &--footer {
    max-width: max-content;
    background: #ffffff;
    border-radius: 12px;
    line-height: 120%;
    padding: 12px 16px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #0070d7;
    width: 100%;
    white-space: nowrap;

    &:hover:not(:disabled) {
      background: #f4faff;
      color: #0861b3;
    }
    &:active {
      background: #f4faff;
      color: #0861b3;
    }
    &:disabled {
      background: #f4faff;
      color: #c5cdd5;
      border: none;
      cursor: not-allowed;
    }
  }
  &--filter {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    text-align: center;

    /* Primary */

    color: #0070d7;
    border: none;
    background: #fff;
  }
}
.icon {
  margin-left: 4px;
}
@media only screen and (max-width: 600px) {
  .btn {
    &--default {
      max-width: initial;
    }
    &--outlined {
      max-width: initial;
      display: flex;
      align-items:center;
      justify-content: center;
    }
  }
}
</style>
