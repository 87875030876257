import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createStore } from 'vuex'
import { store } from './store'
// import VueSlideToggle from 'vue-slide-toggle'
import { createVfm } from 'vue-final-modal'
const vfm = createVfm()
const storeX = createStore({
    modules: {
        store
    }
})
createApp(App).use(router).use(storeX).use(vfm).mount('#app')