<template>
  <div
    class="card"
    :class="card.class"
    :style="{
      height: `${card.height}px`,
    }"
  >
   <div class="card__icons-mobile">
      <base-icon
        :style="{
          marginTop: `${card.marginTop}px`,
          marginRight: `${card.marginRight}px`,
          marginBottom: `${card.marginBottom}px`,
        }"
        :name="card.iconName"
        :width="card.iconWidth"
        :height="card.iconHeight"
        class="card__icon"
      />
    </div>
    <div class="card__top">
      <h2>{{ card.title }}</h2>
      <base-button variant="primary" @onBtnClick="openBot"></base-button>
    </div>

    <div class="card__icons">
      <base-icon
        :style="{
          marginTop: `${card.marginTop}px`,
          marginRight: `${card.marginRight}px`,
          marginBottom: `${card.marginBottom}px`,
        }"
        :name="card.iconName"
        :width="card.iconWidth"
        :height="card.iconHeight"
        class="card__icon"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: { BaseButton, BaseIcon },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openBot() {
      this.$router.push({
        path: `/AllBots/bot/${this.id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 24px 24px 0 24px;
  &__top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  &__icon {
  }
  &__icons {
    // margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
    &__icons-mobile {
display: none;
  }
}
h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #080015;
  margin: 0;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .card {
       
    padding: 20px 16px;
    &__top {
    }
    &__icon {
    }
    &__icons {
      display: none;
    }
      &__icons-mobile {
    // margin-top: 40px;
    // width: 100%;
    display: flex;
    margin-right: 16px;
  }
  }
}
</style>
