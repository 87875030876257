<template>
  <div class="card" @click="openBot">
    <div class="card__top">
      <div class="card__top__icon">
        <base-icon :name="card.icon" :height="56" :width="56" />
        <div class="card__top__text">
          <h2>{{ card.title }}</h2>
          <p class="card__top__text__p">{{ card.category }}</p>
        </div>
      </div>
      <p class="card__text">{{ card.subtitle }}</p>
      <base-button class="s-btn" variant="outlined" @onBtnClick="openBot"
        >Подробнее ></base-button
      >
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  components: { BaseButton, BaseIcon },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: "1",
    };
  },
  
  methods: {
    openBot() {
      this.$router.push({
        path: `/AllBots/bot/${this.card.ids}`,
          // path: `/AllBots/bot/${this.id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  max-width: 302px;
  width: 100%;
  cursor: pointer;
  // height: 223px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #dddeea;
  border-radius: 12px;
  margin-left: 24px;
  margin-bottom: 24px;
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    /* gray - 400 */

    color: #555f6f;

    opacity: 0.7;
    margin: 0;
    margin-bottom: 16px;
  }
  &__top {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &__text {
      margin-left: 12px;
      &__p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* gray - 400 */

        color: #555f6f;

        opacity: 0.7;
        margin: 0;
      }
    }
  }
}
h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* black */
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space:nowrap; 
  width:180px;
  color: #15114e;
  margin: 0;
  margin-bottom: 2px;
}
.s-btn {
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .card {
    max-width: 328px;
    width: 100%;
    cursor: pointer;
    // height: 223px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dddeea;
    border-radius: 12px;
    margin-bottom: 24px;
     margin-left: 0;
    // margin-left: 24px;
    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* or 138% */

      /* gray - 400 */

      color: #555f6f;

      opacity: 0.7;
      margin: 0;
      margin-bottom: 16px;
    }
    &__top {
      &__text {
        &__p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          /* gray - 400 */

          color: #555f6f;

          opacity: 0.7;
          margin: 0;
        }
      }
    }
  }
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    /* black */
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space:nowrap;
    color: #15114e;
    margin: 0;
    margin-bottom: 2px;
  }
  .s-btn {
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
