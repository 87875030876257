<template>
  <div class="services">
    <div class="services__left">
      <base-card
        v-for="card in cardsLeft"
        :key="card"
        :card="card"
        class="card-desktop"
      />
      <base-card
        v-for="card in cardsLeftM"
        :key="card"
        :card="card"
        class="card-mobile"
      />
    </div>
    <div class="services__right">
      <base-card
        v-for="card in cardsRight"
        :key="card"
        :card="card"
        class="card-desktop"
      />
      <base-card
        v-for="card in cardsRightM"
        :key="card"
        :card="card"
        class="card-mobile"
      />
    </div>
  </div>
</template>
<script>
import BaseCard from "@/components/BaseCard.vue";

export default {
  components: { BaseCard },
  data() {
    return {
      cardsLeft: [
        {
          title: "Продажи B2B",
          iconName: "card_icon_1",
          iconWidth: "160",
          iconHeight: "160",
          width: "278",
          height: "264",
          marginTop: "10",
          marginRight: "0",
          marginBottom: "0",
          class: "box-1",
        },
        {
          title: "Отдел качества сервиса",
          iconName: "card_icon_2",
          iconWidth: "200",
          iconHeight: "200",
          width: "495",
          height: "264",
          marginTop: "-30",
          marginRight: "0",
          marginBottom: "4",
          class: "box-2",
        },

        {
          title: "Клиентский сервис",
          iconName: "card_icon_5",
          iconWidth: "140",
          iconHeight: "140",
          width: "278",
          height: "264",
          marginTop: "-25",
          marginRight: "-14",
          marginBottom: "15",
        },
        {
          title: "Закупки",
          iconName: "card_icon_6",
          iconWidth: "120",
          iconHeight: "120",
          width: "224",
          height: "264",
          marginTop: "16",
          marginRight: "0",
          marginBottom: "32",
        },
        {
          title: "HR",
          iconName: "card_icon_7",
          iconWidth: "150",
          iconHeight: "150",
          width: "224",
          height: "264",
          marginTop: "25",
          marginRight: "0",
          marginBottom: "8",
        },
      ],
      cardsRight: [
        {
          title: "Бухгалтерия и налоги",
          iconName: "card_icon_3",
          iconWidth: "80",
          iconHeight: "80",
          width: "387",
          height: "160",
          marginTop: "-70",
          marginRight: "0",
          marginBottom: "20",
          class: "box-3",
        },
        {
          title: "Документооборот",
          iconName: "card_icon_4",
          iconWidth: "80",
          iconHeight: "80",
          width: "387",
          height: "160",
          marginTop: "-38",
          marginRight: "0",
          marginBottom: "0",
          class: "box-4",
        },
        {
          title: "IT",
          iconName: "card_icon_8",
          iconWidth: "80",
          iconHeight: "80",
          width: "387",
          height: "160",
          marginTop: "-42",
          marginRight: "0",
          marginBottom: "0",
        },
      ],
      cardsLeftM: [
        {
          title: "Продажи B2B",
          iconName: "card_icon_1",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
          class: "box-1",
        },
        {
          title: "Отдел качества сервиса",
          iconName: "card_icon_2",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
          class: "box-2",
        },

        {
          title: "Клиентский сервис",
          iconName: "card_icon_5",
           iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
        {
          title: "Закупки",
          iconName: "card_icon_6",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
        {
          title: "HR",
          iconName: "card_icon_7",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
        {
          title: "Бухгалтерия и налоги",
          iconName: "card_icon_3",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
        {
          title: "Документооборот",
          iconName: "card_icon_4",
         iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
        {
          title: "IT",
          iconName: "card_icon_8",
          iconWidth: "80",
          iconHeight: "80",
          width: "328",
          height: "120",
          marginTop: "0",
          marginRight: "0",
          marginBottom: "0",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
// .services {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-column-gap: 24px;
//   grid-row-gap: 24px;
// }
// .box-1 {
//   grid-column-start: 1;
//   grid-column-end: 1;

// }
// .box-2 {
// }
.services {
  display: flex;
  //   align-items: center;
  justify-content: space-between;
  width: 100%;
  &__left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  &__right {
    margin-left: 24px;
  }
}
.box-1 {
  grid-column-start: 1;
  grid-column-end: 1;
}
.box-2 {
  grid-column-start: 2;
  grid-column-end: 4;
}
.box-3 {
  margin-bottom: 24px;
}
.box-4 {
  margin-bottom: 24px;
}
.card-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .card-desktop {
    display: none;
  }
  .card-mobile {
    display: flex;
    align-items: center;
  }
  .services {
    flex-direction: column;
    &__left {
      display: flex;
      flex-direction: column;
    }
    &__right {
      margin-left: 24px;
      display: none;
    }
  }
  .box-1 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .box-2 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .box-3 {
    margin-bottom: 0;
  }
  .box-4 {
    margin-bottom: 0;
  }
}
</style>
