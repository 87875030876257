<template>
  <div>
    <BaseHeader
      @openHamburger="openMobileMenu"
      :mobile="thisIsMobile"
      @openSRModal="openSrModal"
    />
    <div class="container" v-if="!thisIsMobile">
      <div class="info">
        <h1>SCD TEST</h1>
        <image :src="imageData" />

        <p class="info__subtitle">
          Высчитать индекс фрагментации ДНК по фотографии
        </p>

        <div>
          <div
            class="info__upload"
            :class="preview_list.length ? 'uploaded' : ''"
          >
            <div v-if="!preview_list.length" class="info__upload__info">
              <base-icon name="upload" width="24" height="24" />
              <p class="upload__title">
                <b>Загрузите файлы</b>
              </p>
              <p class="upload__title__mobile">
                <b>Загрузите файлы</b>
              </p>
              <p class="upload__subtitle">PNG, JPG, GIF до 10МБ</p>
            </div>

            <template v-if="preview_list.length && !result_list.length">
              <div class="upload__images">
                <div
                  v-for="(item, index) in preview_list"
                  :key="index"
                  class="image"
                >
                  <img :src="item" class="img-fluid" />
                  <CircleProgress
                    v-if="isLoading"
                    size="56"
                    border-width="3"
                    background="#fff"
                    fill-color="#0070D7"
                    empty-color="#fff"
                    :percent="percentage"
                    :viewport="true"
                    class="loader"
                  />
                  <div class="close" @click="reset(index)">
                    <base-icon
                      name="dismiss"
                      width="10"
                      height="10"
                      class="close-icon"
                    />
                  </div>
                  <div
                    v-if="result_list.length"
                    class="download-btn"
                    @click="downloadImgs(item)"
                  >
                    Скачать
                  </div>
                </div>
              </div>
            </template>
            <template v-if="result_list.length">
              <div class="upload__images">
                <div
                  v-for="(item, index) in result_list"
                  :key="index"
                  class="image"
                >
                  <img :src="item" class="img-fluid" />
                  <CircleProgress
                    v-if="isLoading"
                    size="56"
                    border-width="3"
                    background="#fff"
                    fill-color="#0070D7"
                    empty-color="#fff"
                    :percent="percentage"
                    :viewport="true"
                    class="loader"
                  />
                  <div class="close" @click="reset(index)">
                    <base-icon
                      name="dismiss"
                      width="10"
                      height="10"
                      class="close-icon"
                    />
                  </div>
                  <div
                    v-if="result_list.length"
                    class="download-btn"
                    @click="downloadImgs(item)"
                  >
                    Скачать
                  </div>
                </div>
              </div>
            </template>
            <label class="input-file" v-if="!preview_list.length">
              <input
                type="file"
                accept="image/*"
                multiple="multiple"
                @change="previewMultiImage"
                id="my-file"
                ref="file"
              />

              <span class="input-file-btn"
                ><base-icon
                  name="upload-white"
                  width="16"
                  height="16"
                  class="upload__icon"
                />Выберите файл</span
              >
            </label>
          </div>
          <div class="uploaded__btns" v-if="preview_list.length && !result_list.length">
            <label class="input-file">
              <input
                type="file"
                accept="image/*"
                multiple="multiple"
                @change="previewMultiImage"
                id="my-file"
              />

              <span class="input-file-btn-white"
                ><base-icon
                  name="upload-blue"
                  width="16"
                  height="16"
                  class="upload__icon"
                />Выберите файл</span
              >
            </label>
            <base-button @onBtnClick="processImage"
              >Конвертировать файлы ({{ fileCount }})</base-button
            >
          </div>

          <div class="download-btns" v-if="result_list.length">
            <base-button @onBtnClick="donwloadAllImages"
              >Cкачать все файлы</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import { mapGetters, mapActions } from "vuex";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";

export default {
  components: { BaseHeader, BaseIcon, BaseButton, CircleProgress },
  data() {
    return {
      thisIsMobile: false,
      preview: null,
      image: null,
      preview_list: [],
      result_list: [],
      image_list: [],
      formData: null,
      file: "",
      openSr: false,
      formDatas: [],
      isLoading: false,
      resultImg: false,
      percentage: 0,
    };
  },
  computed: {
    ...mapGetters({
      getResponseFromBot: "getResponseFromBot",
    }),
    fileCount() {
      return this.preview_list.length;
    },
    percent() {
      return this.percentage.toFixed();
    },
  },
  methods: {
    ...mapActions({
      sendPics: "sendPics",
    }),
    openSrModal() {
      this.thisIsMobile = false;
      this.openSr = true;
    },
    openMobileMenu(mobileMenu) {
      console.log(mobileMenu);
      this.thisIsMobile = !this.thisIsMobile;
    },
    previewMultiImage: function (event) {
      event.preventDefault();
      var input = event.target;
      this.file = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file", this.file);
      this.formDatas.push(this.formData);
      console.log(">> formData >> ", this.formDatas);

      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },

    processImage() {
      var intval = setInterval(() => {
        if (this.percentage < 95) this.percentage += 5;
        else clearInterval(intval);
      }, 30);

      this.formDatas.forEach((fD) => {
        this.isLoading = true;
        this.sendPics(fD).then(() => {
          console.log(this.getResponseFromBot.data);
          this.result_list.push(
            "data:image/png;base64," + this.getResponseFromBot.data.img_bytes
          );
          console.log(this.result_list);
          this.isLoading = false;
          this.resultImg = true;
        });
      });
    },
    downloadImgs(item) {
      var fileLink = document.createElement("a");
      fileLink.href = item;
      fileLink.download = "result.png";
      fileLink.click();
    },
    donwloadAllImages() {
      console.log(this.preview_list);
      this.result_list.forEach((image) => {
        var fileLink = document.createElement("a");
        fileLink.href = image;
        fileLink.download = "result.png";
        fileLink.click();
      });
    },
    reset: function (idx) {
      this.image_list.splice(idx, 1);
      this.preview_list.splice(idx, 1);
      this.formDatas.splice(idx, 1);
      this.result_list.splice(idx,1)
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;

  color: #15114e;
}
// .container {
//   position: relative;

// }
.download-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}
.loader-bot {
  position: absolute;
}
.loader {
  position: absolute !important;
  top: 33%;
  right: 33%;
}
.info {
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* gray - 400 */
    margin: 0;
    color: #555f6f;
  }

  &__upload {
    width: 800px;
    margin: 0 auto;
    margin-top: 42px;

    border: 1px dashed #c5cdd5;
    border-radius: 16px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &__info {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
.upload {
  &__icon {
    margin-right: 4px;
  }
  &__title__mobile {
    display: none;
  }
  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #15114e;
    white-space: nowrap;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    text-align: center;

    /* gray - 200 */

    color: #b0bdc6;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    margin: 0;
    margin-bottom: 20px;
  }

  &__images {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    img {
      width: 156px;
      height: 156px;
      border-radius: 8px;
      //   margin-right: 16px;
    }
  }
}
b {
  font-weight: 600;
}
input {
  border: none;
}
input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
.input-file-btn {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  max-width: max-content;
  background: #0070d7;
  border-radius: 12px;
  line-height: 120%;
  padding: 12px 16px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  white-space: nowrap;
}
.input-file-btn-white {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  max-width: max-content;
  background: #ffffff;
  border: 1px solid #0070d7;
  border-radius: 12px;
  line-height: 120%;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #0070d7;
  width: 100%;
  white-space: nowrap;
}
.uploaded {
  align-items: flex-start;
    overflow-x: auto;
  &__btns {
    display: flex;
    width: 800px;
    margin: 0 auto;
    margin-top: 28px;
    justify-content: space-between;
  }
}
.image {
  position: relative;
  background-color: black;
  border-radius: 8px;
  width: 156px;
  height: 156px;
  margin-right: 16px;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
  }
}
.download-btn {
  cursor: pointer;
  position: absolute;
  top: 75%;
  right: 5%;
  border-radius: 12px;
  padding: 6px 16px;
  border: 1px solid #0070d7;
  background: #fff;
  font-size: 14px;
  color: #0070d7;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #0070d7;
    color: #fff;
  }
}
.img-fluid {
  opacity: 0.5;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: left;
  }
  .info {
    &__subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */

      /* gray - 400 */
      margin: 0;
      color: #555f6f;
    }

    &__upload {
      width: 328px;
      margin: 0;
      margin-top: 42px;

      border: 1px dashed #c5cdd5;
      border-radius: 16px;
      padding: 48px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__info {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  .upload {
    &__icon {
      margin-right: 4px;
    }
    &__title__mobile {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: #15114e;
      white-space: nowrap;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 4px;
    }
    &__title {
      display: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: #15114e;
      white-space: nowrap;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 4px;
    }

    &__subtitle {
      display: flex;
      align-items: center;
      text-align: center;

      /* gray - 200 */

      color: #b0bdc6;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      margin: 0;
      margin-bottom: 20px;
    }

    &__images {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      // overflow-x:   auto;
      img {
        width: 156px;
        height: 156px;
        border-radius: 8px;
        //   margin-right: 16px;
      }
    }
  }
  b {
    font-weight: 600;
  }
  input {
    border: none;
  }
  input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
  .input-file-btn {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    max-width: max-content;
    background: #0070d7;
    border-radius: 12px;
    line-height: 120%;
    padding: 12px 16px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    white-space: nowrap;
  }
  .input-file-btn-white {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    max-width: 328px;
    background: #ffffff;
    border: 1px solid #0070d7;
    border-radius: 12px;
    line-height: 120%;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #0070d7;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    margin-bottom: 16px;
  }
  .uploaded {
    align-items: flex-start;

    &__btns {
      display: flex;
      width: 328px;
      flex-direction: column;
      margin: 0;
      margin-top: 25px;
      justify-content: center;
    }
  }
  .image {
    position: relative;
    background-color: black;
    border-radius: 8px;
    width: 156px;
    height: 156px;
    margin-right: 16px;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
    }
  }
  .img-fluid {
    opacity: 0.5;
  }
}
</style>
