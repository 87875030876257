import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from '@/views/HomePage.vue'
import AllBots from '@/views/AllBots.vue'
import Bot from '@/views/bot/_bot'
import Test from '@/views/bot/test/_botTest'


const routes = [{
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/AllBots',
        name: 'AllBots',
        component: AllBots
    },
    {
        path: '/AllBots/bot/:bot',
        name: 'Bot',
        component: Bot
    },
    {
        path: '/AllBots/bot/:bot/:test',
        name: 'Test',
        component: Test
    },
];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

export default router;