<template>
  <div>
    <BaseHeader @openHamburger="openMobileMenu" :mobile="thisIsMobile"  @openSRModal="openSrModal" />
    <div v-if="!thisIsMobile">
      <div class="container wrapper">
        <div class="info">
          <base-icon name="BOT-ICON-1" :height="80" :width="80" />

          <h1>{{ botItem.title }}</h1>
          <p>{{ botItem.category }}</p>
          <div class="info__btns">
            <base-button>Заказать консультацию</base-button>
            <base-button
              variant="outlined"
              class="info__btn"
              @onBtnClick="openTest"
              >Попробовать бесплатно</base-button
            >
          </div>
          <h2>Описание</h2>
          <p class="problem" v-if="botItem.problem">Проблема:</p>
          <p>
            {{ botItem.problem ? botItem.problem : botItem.desc }}
          </p>
          <ul v-for="services in botItem.services" :key="services">
            <li>{{ services }}</li>
          </ul>
          <p>
            {{ botItem.end ? botItem.end : "" }}
          </p>
          <p class="problem" v-if="botItem.solutions || botItem.solution">
            Решение:
          </p>
          <p v-if="botItem.solutions">{{ botItem.solutions }}</p>
          <p class="problem" v-if="botItem.question">{{botItem.question}}</p>
          <ul v-for="solution in botItem.solution" :key="solution">
            <li>{{ solution }}</li>
          </ul>
          <p class="problem" v-if="botItem.result">Результат:</p>
          <p>{{botItem.adv}}</p>
          <ul v-for="result in botItem.result" :key="result">
            <li>{{ result }}</li>
          </ul>
          <h2>Характеристики:</h2>

          <p class="features">
            Инструменты: <b>{{ botItem?.feature?.instrument }}</b>
          </p>
          <p class="features">
            Время исполнения: <b>{{ botItem?.feature?.time }}</b>
          </p>
          <p class="features">
            Сложность применения: <b>{{ botItem?.feature?.difficulty }}</b>
          </p>
          <base-button variant="primary" class="s-btn">Подробнее</base-button>

          <h2>FAQ</h2>
          <div class="faq">
            <div class="faq__point" @click="openFAQ1 = !openFAQ1">
              <div class="faq__point__title">
                <h4>Что такое RPA?</h4>
                <base-icon
                  name="arrow"
                  :height="24"
                  :width="24"
                  :class="openFAQ1 ? 'rotate' : ''"
                />
              </div>

              <slide-up-down v-model="openFAQ1" :duration="700">
                <p>
                  RPA (Robotic Process Automation) - это использование
                  компьютерных программ для автоматизации повторяющихся и
                  рутинных задач, которые обычно выполняют люди. Например, RPA
                  может помочь автоматизировать процесс заполнения форм,
                  обновления баз данных, отправки сообщений на электронную
                  почту, обработки заказов и других задач. Это помогает
                  сократить время и затраты на ручную работу, снизить количество
                  ошибок и повысить эффективность бизнес-процессов.
                </p>
              </slide-up-down>
            </div>
            <div class="faq__point" @click="openFAQ1 = !openFAQ1">
              <div class="faq__point__title">
                <h4>Что такое RPA?</h4>
                <base-icon
                  name="arrow"
                  :height="24"
                  :width="24"
                  :class="openFAQ1 ? 'rotate' : ''"
                />
              </div>

              <slide-up-down v-model="openFAQ1" :duration="700">
                <p>
                  RPA (Robotic Process Automation) - это использование
                  компьютерных программ для автоматизации повторяющихся и
                  рутинных задач, которые обычно выполняют люди. Например, RPA
                  может помочь автоматизировать процесс заполнения форм,
                  обновления баз данных, отправки сообщений на электронную
                  почту, обработки заказов и других задач. Это помогает
                  сократить время и затраты на ручную работу, снизить количество
                  ошибок и повысить эффективность бизнес-процессов.
                </p>
              </slide-up-down>
            </div>
          </div>
        </div>
        <div class="sidebar">
          <h3>Похожие боты</h3>
          <div class="bots">
            <BotCard
              class="bots__bot"
              v-for="card in bots.botList"
              :key="card.title"
              :card="card"
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="container footer__content">
          <p class="footer__left">©2023 SkyMetrics. Все права защищены.</p>
          <div class="footer__right">
            <p class="footer__right__title">Публичная оферта</p>
            <p class="footer__right__title">Политика конфиденциальности</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import BotCard from "@/components/BotCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import SlideUpDown from "vue3-slide-up-down";
import botList from "@/repositories/botList.js";
export default {
  components: { BaseHeader, BotCard, BaseButton, BaseIcon, SlideUpDown },
  data() {
    return {
      openSr:false,
      botItem: {},
      openFAQ1: false,
      thisIsMobile: false,
      bots: botList,
    };
  },
  mounted() {
    this.botItem = this.bots?.botList.find(
      (bot) => bot.ids === this.$route.params.bot
    );
  },
  methods: {
    openTest() {
      this.$router.push(`/AllBots/bot/:bot/:test`);
    },
    openMobileMenu(mobileMenu) {
      console.log(mobileMenu);
      this.thisIsMobile = !this.thisIsMobile;
    },
       openSrModal() {
      this.thisIsMobile = false;
      this.openSr = true;
    },
  },

};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: 100%;
}
.problem {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;

  /* or 22px */

  /* gray - 400 */

  color: #555f6f;
  margin-top: 16px;
  margin-bottom: 20px;
}
h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #15114e;
  margin: 0;
  margin-bottom: 20px;
}
h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  /* black */

  color: #15114e;

  margin: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}
.sidebar {
  width: 302px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 132px;

  &__btns {
    display: flex;
    margin-top: 32px;
    align-items: center;
  }

  &__btn {
    margin-left: 32px;
  }
}
.bots {
  &__bot {
    margin-bottom: 16px;
  }
}
h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  /* black */

  color: #15114e;
  margin: 0;
  margin-bottom: 12px;
  margin-top: 24px;
}
.footer {
  background: #f9f9f9;
  padding: 24px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #555f6f;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #0070d7;
      margin: 0;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
p {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  /* gray - 400 */

  color: #555f6f;
}
.features {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
}
b {
  font-weight: 600;
  font-size: 18px;
  color: #15114e;
}
.s-btn {
  margin-top: 12px;
  margin-bottom: 50px;
}
.faq {
  transition: all 1s ease;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;

  &__point {
    padding: 24px;
    border: 1px solid #dddeea;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 16px;
    // &:first-child {
    //   margin-right: 32px;
    // }
    &__top {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15114e;
  margin: 0;
  white-space: nowrap;
}
svg {
  cursor: pointer;
}
.rotate {
  transition: all 0.5s ease;
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}
// p {
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 120%;
//   /* or 22px */

//   /* gray - 400 */

//   color: #555f6f;
//   margin: 0;
//   margin-top: 16px;
// }
.equal-columns > * {
  flex: 1;
}
ul {
  list-style-position: outside;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 18px;
}
li {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* or 22px */

  /* gray - 400 */

  color: #555f6f;
}
@media only screen and (max-width: 600px) {
  ul {
    list-style-position: outside;
  }
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    /* or 22px */

    /* gray - 400 */

    color: #555f6f;
  }
  .wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #15114e;
    margin: 0;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 24px */

    /* black */

    color: #15114e;

    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .sidebar {
    width: 302px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 0;

    &__btns {
      display: flex;
      margin-top: 32px;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    &__btn {
      margin-left: 0;
      margin-top: 16px;
    }
  }
  .bots {
    &__bot {
      margin-bottom: 16px;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    /* or 43px */

    /* black */
    text-align: left;
    color: #15114e;
    margin: 0;
    margin-bottom: 12px;
    margin-top: 24px;
  }
  .footer {
    background: #f9f9f9;
    padding: 0;

    &__content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__left {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #555f6f;
    }
    &__right {
      margin-top: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #0070d7;
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  p {
    margin: 0;

    font-weight: 400;
    font-size: 16px;
    line-height: 120%;

    /* identical to box height, or 22px */

    /* gray - 400 */

    color: #555f6f;
  }
  .features {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
  b {
    font-weight: 600;
    font-size: 18px;
    color: #15114e;
  }
  .s-btn {
    margin-top: 12px;
    margin-bottom: 0;
  }
  .faq {
    transition: all 1s ease;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

    &__point {
      padding: 12px 16px;
      border: 1px solid #dddeea;
      border-radius: 12px;
      cursor: pointer;
      margin-bottom: 16px;
      // &:first-child {
      //   margin-right: 32px;
      // }
      &__top {
        display: flex;
        align-items: flex-start;
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15114e;
    margin: 0;
    white-space: nowrap;
  }
  svg {
    cursor: pointer;
  }
  .rotate {
    transition: all 0.5s ease;
    transform: rotate(180deg); /* Equal to rotateZ(45deg) */
  }
  // p {
  //   font-weight: 400;
  //   font-size: 18px;
  //   line-height: 120%;
  //   /* or 22px */

  //   /* gray - 400 */

  //   color: #555f6f;
  //   margin: 0;
  //   margin-top: 16px;
  // }
  .equal-columns > * {
    flex: 1;
  }
}
</style>
