<template>
  <div>
    <BaseHeader
      v-if="!showFilter"
      @openHamburger="openMobileMenu"
      :mobile="thisIsMobile"
      @openSRModal="openSrModal"
    />
    <div v-if="!thisIsMobile & !showFilter">
      <div class="container">
        <div class="bots">
          <div class="sidebar">
            <div class="sidebar__top">
              <h2>Фильтры</h2>
              <base-button variant="filter" @onBtnClick="resetFilters"
                >Сбросить</base-button
              >
            </div>
            <div class="sidebar__bottom">
              <div class="sidebar__item">
                <p>Время исполнения</p>
                <input />
              </div>
              <div class="sidebar__item">
                <p>Инструменты</p>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    v-model="checked.instrument"
                    id="UiPath"
                    value="UiPath"
                  />
                  <label for="UiPath" v-text="'UiPath'" />
                </div>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="Python"
                    value="Python"
                    v-model="checked.instrument"
                  />
                  <label for="Python" v-text="'Python'" />
                </div>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="PowerBI"
                    value="PowerBI"
                    v-model="checked.instrument"
                  />
                  <label for="PowerBI" v-text="'PowerBI'" />
                </div>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="OneC"
                    value="OneC"
                    v-model="checked.instrument"
                  />
                  <label for="OneC" v-text="'1C'" />
                </div>
              </div>
              <div class="sidebar__item">
                <p>Сложность применения</p>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="easy"
                    value="easy"
                    v-model="checked.difficulty"
                  />
                  <label for="easy" v-text="'Легкая'" />
                </div>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="medium"
                    value="medium"
                    v-model="checked.difficulty"
                  />
                  <label for="medium" v-text="'Средняя'" />
                </div>
                <div class="checkboxes">
                  <input
                    type="checkbox"
                    class="checkbox-custom"
                    id="hard"
                    value="hard"
                    v-model="checked.difficulty"
                  />
                  <label for="hard" v-text="'Сложная'" />
                </div>
              </div>
            </div>
          </div>
          <div class="bots__card">
            <div class="bots__card__top">
              <p>Сортировать по:</p>
              <base-button variant="filter">Обновление</base-button>
            </div>
            <div class="mobile__bots">
              <div class="filter" @click="openFilter">
                <base-icon name="filter" :height="24" :width="24" />
                <p class="filter__subtitle">Фильтры</p>
                <div
                  class="red"
                  v-if="
                    checked.instrument.length > 0 ||
                    checked.difficulty.length > 0
                  "
                ></div>
              </div>
              <div class="sort">
                <p class="sort__subtitle">Обновление</p>
                <base-icon name="arrow-down" :height="16" :width="16" />
              </div>
            </div>

            <div class="bots__card__card" v-if="filteredValue.length > 0">
              <BotCard
                v-for="card in filteredValue"
                :key="card.title"
                :card="card"
              ></BotCard>
            </div>
            <div v-else class="no-data">Нет данных</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="container footer__content">
          <p class="footer__left">©2023 SkyMetrics. Все права защищены.</p>
          <div class="footer__right">
            <p class="footer__right__title">Публичная оферта</p>
            <p class="footer__right__title">Политика конфиденциальности</p>
          </div>
        </div>
      </div>
    </div>
    <div class="filters container" v-if="showFilter">
      <div class="sidebar__top">
        <div class="sidebar__top__reset">
          <h2>Фильтры</h2>
          <div class="reset" @click="resetFilters">Сбросить</div>
        </div>

        <base-icon
          name="close"
          :height="24"
          :width="24"
          @click="showFilter = false"
        />
      </div>
      <div class="sidebar__bottom">
        <div class="sidebar__item">
          <p>Время исполнения</p>
          <input />
        </div>
        <div class="sidebar__item">
          <p>Инструменты</p>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              v-model="checked.instrument"
              id="UiPath"
              value="UiPath"
            />
            <label for="UiPath" v-text="'UiPath'" />
          </div>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="Python"
              value="Python"
              v-model="checked.instrument"
            />
            <label for="Python" v-text="'Python'" />
          </div>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="PowerBI"
              value="PowerBI"
              v-model="checked.instrument"
            />
            <label for="PowerBI" v-text="'PowerBI'" />
          </div>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="OneC"
              value="OneC"
              v-model="checked.instrument"
            />
            <label for="1C" v-text="'1C'" />
          </div>
        </div>
        <div class="sidebar__item">
          <p>Сложность применения</p>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="easy"
              value="easy"
              v-model="checked.difficulty"
            />
            <label for="easy" v-text="'Легкая'" />
          </div>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="medium"
              value="medium"
              v-model="checked.difficulty"
            />
            <label for="medium" v-text="'Средняя'" />
          </div>
          <div class="checkboxes">
            <input
              type="checkbox"
              class="checkbox-custom"
              id="hard"
              value="hard"
              v-model="checked.difficulty"
            />
            <label for="hard" v-text="'Сложная'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BotCard from "@/components/BotCard.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import botList from "@/repositories/botList.js";

export default {
  components: { BaseHeader, BaseButton, BotCard, BaseIcon },
  data() {
    return {
      openSr: false,
      thisIsMobile: false,
      showFilter: false,
      bots: botList,
      checked: {
        instrument: [],
        difficulty: [],
      },
    };
  },
  computed: {
    filteredValue() {
      let items = [];
      if (
        this.checked.instrument.length === 0 &&
        this.checked.difficulty.length === 0
      ) {
        return this.bots.botList;
      } else {
        if (this.checked.instrument.length > 0) {
          items = this.bots?.botList.filter(
            (item) =>
              this.checked.instrument.indexOf(item.feature.instrument) !== -1
          );
        }
        if (this.checked.difficulty.length > 0) {
          if (this.checked.instrument.length > 0) {
            items = items.filter(
              (item) =>
                this.checked.difficulty.indexOf(item.feature.difficulty) !== -1
            );
          } else {
            items = this.bots?.botList.filter(
              (item) =>
                this.checked.difficulty.indexOf(item.feature.difficulty) !== -1
            );
          }
        }
        return items;
      }
    },
  },

  methods: {
    openSrModal() {
      this.thisIsMobile = false;
      this.openSr = true;
    },
    openMobileMenu(mobileMenu) {
      console.log(mobileMenu);
      this.thisIsMobile = !this.thisIsMobile;
    },
    openFilter() {
      this.showFilter = !this.showFilter;
    },
    resetFilters() {
      this.checked = {
        instrument: [],
        difficulty: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* gray - 200 */

  color: #b0bdc6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bots {
  display: flex;
  width: 100%;
  &__card {
    width: 100%;

    margin-left: 16px;
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 32px;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 138% */

        /* gray - 200 */

        color: #b0bdc6;
        margin: 0;
      }
    }

    &__card {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #15114e;
  margin: 0;
}
.sidebar {
  max-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #15114e;
    margin: 0;
    margin-bottom: 16px;
  }
}
label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: center;

  /* black */

  color: #15114e;
}
.checkboxes {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
// input[type="checkbox" i] {
//   width: 28px;
//   height: 28px;
//   border-radius: 4px;

//   background: #ffffff;
//   /* Gray */
//   border: 0.5px solid #dddeea;
//   box-sizing: border-box;

//   cursor: pointer;
// }

// input[type="checkbox" i]:checked {
//   /* green */
//   background: #0070d7;
//   border-radius: 4px;

//   box-sizing: border-box;
// }
.checkbox-custom {
  position: absolute;
  z-index: -1;
  opacity: 0;
  //      width: 28px;
  //   height: 28px;
  //   border-radius: 4px;

  //   background: #ffffff;
  //   /* Gray */
  //   border: 0.5px solid #dddeea;
  //   box-sizing: border-box;

  //   cursor: pointer;
}
.checkbox-custom + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #15114e;
}
.checkbox-custom + label::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  flex-grow: 0;

  background: #ffffff;
  /* gray-50 */
  margin-right: 6px;

  border: 0.5px solid #dddeea;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.checkbox-custom:checked + label::before {
  border-color: #0b76ef;
  background-color: #0070d7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.footer {
  background: #f9f9f9;
  padding: 24px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #555f6f;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #0070d7;
      margin: 0;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
.mobile__bots {
  display: none;
}
.filters {
  display: none;
}
.reset {
  display: none;
}
@media only screen and (max-width: 600px) {
  .reset {
    display: inline;
    color: #0070d7;
    text-align: center;
    font-size: 14px;
 margin-left: 8px;
    line-height: 120%;
  }
  .filters {
    display: flex;
    flex-direction: column;
  }
  .mobile__bots {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      align-items: center;
      position: relative;
      .red {
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background: #ff3346;
        position: absolute;
        top: 0;
        left: 17px;
      }
      &__subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Primary */

        color: #0070d7;
        margin: 0;
        margin-right: 8px;
      }
    }
    .sort {
      display: flex;
      align-items: center;
      &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 17px */

        text-align: center;

        /* Primary */

        color: #0070d7;
        margin: 0;
        margin-right: 4px;
      }
    }
  }

  .bots {
    display: flex;
    width: 100%;
    &__card {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      &__top {
        display: none;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 32px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #b0bdc6;
          margin: 0;
        }
      }

      &__card {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #15114e;
    margin: 0;
  }
  .sidebar {
    display: none;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #15114e;
      margin: 0;
      margin-bottom: 16px;
    }
    &__top {
      &__reset{
        display: flex;
        align-items: flex-end;
      }
      
    }
    &__item {
      width: 100%;
    }
  }
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    display: flex;
    align-items: center;

    /* black */

    color: #15114e;
  }
  .checkboxes {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  // input[type="checkbox" i] {
  //   width: 28px;
  //   height: 28px;
  //   border-radius: 4px;

  //   background: #ffffff;
  //   /* Gray */
  //   border: 0.5px solid #dddeea;
  //   box-sizing: border-box;

  //   cursor: pointer;
  // }

  // input[type="checkbox" i]:checked {
  //   /* green */
  //   background: #0070d7;
  //   border-radius: 4px;

  //   box-sizing: border-box;
  // }
  .checkbox-custom {
    position: absolute;
    z-index: -1;
    opacity: 0;
    //      width: 28px;
    //   height: 28px;
    //   border-radius: 4px;

    //   background: #ffffff;
    //   /* Gray */
    //   border: 0.5px solid #dddeea;
    //   box-sizing: border-box;

    //   cursor: pointer;
  }
  .checkbox-custom + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  .checkbox-custom + label::before {
    content: "";
    display: inline-block;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    flex-grow: 0;

    background: #ffffff;
    /* gray-50 */
    margin-right: 6px;

    border: 0.5px solid #dddeea;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .checkbox-custom:checked + label::before {
    border-color: #0b76ef;
    background-color: #0070d7;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  .footer {
    background: #f9f9f9;
    padding: 0;

    &__content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__left {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #555f6f;
    }
    &__right {
      margin-top: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #0070d7;
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
