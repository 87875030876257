import PostRepository from "./repositories/PostRepository";
import endpoints from "./repositories/RepositoryFactory"

export const store = {
    state: () => ({
        response: [],
        requestResponse: []
    }),
    getters: {
        getResponseFromBot: state => state.response,
        getRequestResponse: state => state.requestResponse
    },
    mutations: {
        SET(state, [namespace, data]) {
            state[namespace] = data
        },
    },
    actions: {
        async sendPics({ commit }, payload) {
            const response = await PostRepository.post(endpoints.Bot.modal, payload)
            commit("SET", ['response', response])
        },
        async sendRequest({ commit }, payload) {
            const response = await PostRepository.post(endpoints.Bot.request, payload)
            commit("SET", ['requestResponse', response])
        }
    }
}