<template>
  <svg
    :style="{ width: `${width}px`, height: `${height}px`}"
  >
    <use :xlink:href="require(`@/assets/icons.svg`) + `#${name}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: Number,
      default: 16,
    },
     height: {
      type: Number,
      default: 16,
    },
  }
};
</script>