<template>
  <div v-if="openSr">
    <div class="modal-head">
      <base-icon
        name="logo"
        :height="20"
        :width="100"
        class="main-icon-mobile"
      />
      <base-icon
        name="close"
        width="24"
        height="24"
        @click="$emit('closeSr')"
      />
    </div>

    <h3 class="modal__title">Заполните форму и мы с вами свяжемся:</h3>

    <div class="inputs">
      <div class="input">
        <p>Название вашей компаний *</p>
        <input
          placeholder="Введите название"
          v-model="nameOrg"
          :class="{ 'error-input': v$.nameOrg.$error }"
        />
        <div v-if="v$.nameOrg.$error" class="error">Обязательное поле</div>
      </div>
      <div class="input">
        <p>Сфера бизнеса *</p>

        <MultiSelect
          :options="busFields"
          v-model="busField"
          :class="{ 'error-input': v$.nameOrg.$error }"
        />

        <div v-if="v$.busField.$error" class="error">Обязательное поле</div>
      </div>
      <div class="input">
        <p>Размер компании</p>
        <MultiSelect :options="companySizes" v-model="companySize" />
      </div>
      <div class="input">
        <p>Ваш номер *</p>
        <input placeholder="+7 (7**) *** ** **" v-model="phoneNumber" />
      </div>

      <div class="input">
        <p>Вашa почта</p>
        <input
          placeholder="Введите почту"
          v-model="email"
          :class="{ 'error-input': v$.nameOrg.$error }"
        />
        <div v-if="v$.email.$error" class="error">Обязательное поле</div>
      </div>
      <base-button @onBtnClick="submitForm" class="send-btn"
        >Оставить заявку</base-button
      >
    </div>
    <p class="bottom-text">
      Оставляя данные на сайте, Вы соглашаетесь с
      <a>Политикой конфиденциальности</a>
    </p>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import BaseIcon from "@/components/BaseIcon.vue";
import { mapGetters, mapActions } from "vuex";

import "@vueform/multiselect/themes/default.css";
import MultiSelect from "@vueform/multiselect";
export default {
  components: {
    BaseButton,
    MultiSelect,
    BaseIcon,
  },
  props: {
    openSr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v$: useValidate(),
      nameOrg: "",
      busField: "",
      busFields: [
        "Продажа B2B",
        "Отдела качество сервиса",
        "Бухгалтерия и налоги",
        "Клиентский сервис",
        "Закупки",
        "HR",
        "IT",
      ],
      phoneNumber: "",
      email: "",
      companySize: "",
      companySizes: [
        "Малое предприятие",
        "Среднее предприятие",
        "Крупное предприятие",
      ],
    };
  },
  computed: {
    ...mapGetters({
      getRequestResponse: "getRequestResponse",
    }),
  },
  validations() {
    return {
      nameOrg: { required },
      busField: { required },
      email: { required, email }, // Matches this.contact.email
    };
  },
  methods: {
    ...mapActions({
      sendRequest: "sendRequest",
    }),
    submitForm() {
      this.v$.$validate(); // checks all inputs

      if (!this.v$.$error) {
        // if ANY fail validation
        let payload = {
          company_name: this.nameOrg,
          business_area: this.busField,
          company_size: this.companySize,
          phone_number: this.phoneNumber,
          email: this.email,
          request_is_processed: "on",
          comment: "test",
        };
        this.sendRequest(payload);
        console.log("fd ");
      } else {
        console.log("fd ");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  &__title {
    color: #15114e;
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 100%;
    margin: 0;
    margin-top: 24px;
  }
}
.input {
  margin-top: 24px;
  p {
    font-size: 12px;
    line-height: 20px;
    color: #555f6f;
    margin: 0;
    margin-bottom: 4px;
  }
}
.inputs {
  margin-top: 24px;
}

.send-btn {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 28px;
}
.bottom-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #b0bdc6;
  margin: 0;
}
a {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin: 0;
  color: #0070d7;
}
.send-reques-modal {
  position: relative;
}
.icon {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
.error {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff3346;
  margin: 0;
  margin-top: 4px;
}
.error-input {
  border: 1px solid #ff3346;
}
// .main{
//   position: relative;
// }
</style>
