<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
.container {
  padding: 20px 80px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #15114e;
 font-family: Inter;

}
h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 110%;
  color: #15114e;
  margin: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;

  color: #0070d7;
  text-decoration: none;
}
input {
  background: #ffffff;
  width: 98%;
  border: 1px solid #d3d4e5;
  border-radius: 12px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* black */

  color: #15114e;

  margin: 0;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #b0bdc6;
  }
  &:focus {
    border: 1px solid #d3d4e5;
  }
}
input:focus {
  outline: none;
}
@media only screen and (max-width: 600px) {
  .container {
    padding: 18px 16px;
  }
  h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 110%;
    /* or 29px */

    text-align: center;

    /* black */

    color: #15114e;
  }
  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
