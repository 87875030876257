<template>
  <div class="main">
    <BaseHeader
      v-if="!openSr"
      @openHamburger="openMobileMenu"
      :mobile="thisIsMobile"
      @openSRModal="openSrModal"
      @scrollToEl="scrollToEl"
    />
    <div v-if="!thisIsMobile & !openSr">
      <div class="container">
        <div class="home">
          <div class="home__right__mobile">
            <img :src="imgRobot" class="robot-mobile" />
            <!-- <div class="home__right__icon">
              <base-icon name="icon-main" height="32.59" width="21.72" />
            </div>
            <div class="home__right__icon_2">
              <base-icon name="icon-main-2" height="20.19" width="17.94" />
            </div>

            <base-icon
              name="icon-main-3"
              height="25.41"
              width="24.69"
              class="home__right__icon_3"
            /> -->
          </div>
          <div class="home__left">
            <h1>Небесная точность в автоматизации бизнеса с SkyMetrics</h1>
            <p>
              SkyMetrics обеспечивает максимально точные и надежные результаты в
              процессе автоматизации бизнес-процессов, что помогает клиентам
              компании принимать более обоснованные и эффективные решения
            </p>
            <SendRequestModal :openModal="openModal" class="desktop" />
            <base-button @onBtnClick="openSr = true" class="mobile"
              >Заказать консультацию</base-button
            >
          </div>

          <div class="home__right">
            <img :src="imgRobot" class="robot" />
            <div class="home__right__icon">
              <base-icon name="icon-main" height="32.59" width="21.72" />
            </div>
            <div class="home__right__icon_2">
              <base-icon name="icon-main-2" height="20.19" width="17.94" />
            </div>

            <base-icon
              name="icon-main-3"
              height="25.41"
              width="24.69"
              class="home__right__icon_3"
            />
          </div>
        </div>
        <div class="home__info">
          <div class="home__info__left">
            <div class="home__info__left__numbers">
              <p class="home__info__left__numbers_title">+35</p>
              <div class="home__info__icons">
                <base-icon
                  class="home__info__icon"
                  name="home-info"
                  height="19.9"
                  width="17.8"
                />
              </div>
            </div>
            <p class="home__info__text">
              Ботов для автоматизации бизнес процессов
            </p>
          </div>

          <div class="home__info__right">
            <div class="home__info__right__numbers">
              <p class="home__info__right__numbers_title">+20</p>
              <div class="home__info__icons">
                <base-icon
                  class="home__info__icon-2"
                  name="home-info-2"
                  height="17.9"
                  width="18"
                />
              </div>
            </div>
            <p class="home__info__text">
              Предприятий успешно протестировали сервис
            </p>
          </div>
        </div>
        <div class="home__adv" ref="whatBotCanDo">
          <div class="home__adv__title">
            <p>Возможности бота</p>
            <h2>Бот вам поможет:</h2>
          </div>
          <div class="home__advs">
            <div class="home__adv__cards" v-for="adv in advs" :key="adv">
              <div class="home__adv__cards-icon">
                <base-icon name="tick" width="19.25" height="19.25" />
              </div>

              <p class="home__adv__cards-title">{{ adv }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="info__container container">
          <div class="info__left">
            <img :src="laptop" class="laptop" />
          </div>
          <div class="info__right">
            <h2>Автоматизируйте свой бизнес - ускорьте успех!</h2>
            <p class="info__right__subtitle">
              Боты по автоматизации процессов могут помочь в автоматизации
              различных видов бизнеса, начиная от малых до крупных компаний
            </p>
            <!-- <base-button>Заказать консультацию</base-button> -->
            <SendRequestModal :openModal="openModal" class="desktop" />
            <base-button @onBtnClick="openSr = true" class="mobile"
              >Заказать консультацию</base-button
            >
          </div>
        </div>
      </div>
      <div class="advantages container">
        <div class="advantages__title">
          <p>Преимущество</p>
          <h2>Почему мы?</h2>
        </div>
        <div class="advantages__points">
          <div
            class="advantages__point"
            v-for="advantage in advantages"
            :key="advantage"
          >
            <img class="advantages__point__icon" :src="advantage.img" />
            <div class="advantages__point__titles">
              <h3>{{ advantage.title }}</h3>
              <p>{{ advantage.subtitle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bots container" ref="services">
        <div class="bots__title">
          <p>Боты</p>
          <div class="bots__bottom">
            <h2>Сервисы</h2>
            <base-button variant="primary" @onBtnClick="openBots"
              >Смотреть все</base-button
            >
          </div>
        </div>
        <base-services />
      </div>

      <div class="partner-client container">
        <div class="partner">
          <div class="partner__title">
            <p>ПАРТНЕРЫ</p>
            <h2>Наши партнеры</h2>
          </div>
          <img :src="rpa" class="rpa" />
        </div>
        <div class="client">
          <div class="client__title">
            <p>КЛИЕНТЫ</p>
            <h2>Наши клиенты</h2>
          </div>
          <img :src="jumis" class="jumis" />
        </div>
      </div>

      <div class="numbers">
        <div class="numbers__title container">
          <h2>Мы в цифрах</h2>
          <p class="numbers__title__title">
            Мы предлагаем услуги, которые помогут вам независимо от того, хотите
            ли вы начать процесс роботизации самостоятельно либо с нашей помощью
          </p>
        </div>
        <div class="numbers__progress">
          <div>
            <h3>{{ percent }}%</h3>
            <p class="numbers__progress__sub">Уменьшили вероятность ошибки</p>
          </div>
          <div>
            <h3>в {{ saving }} раза</h3>
            <p class="numbers__progress__sub">Сэкономили персонал</p>
          </div>
          <div>
            <h3>+{{ enterprise }}</h3>
            <p class="numbers__progress__sub">
              Предприятий успешно <br />
              протестировали сервис
            </p>
          </div>
        </div>
      </div>
      <div ref="faq"><BaseFAQ /></div>

      <div class="container">
        <div class="pre-footer">
          <h2>Готовы работать с нами?</h2>
          <SendRequestModal :openModal="openModal" footer class="desktop" />
          <base-button
            @onBtnClick="openSr = true"
            variant="outlined"
            class="mobile"
            >Заказать консультацию</base-button
          >
        </div>
      </div>
      <div class="footer">
        <div class="container footer__content">
          <p class="footer__left">©2023 SkyMetrics. Все права защищены.</p>
          <div class="footer__right">
            <p class="footer__right__title">Публичная оферта</p>
            <p class="footer__right__title">Политика конфиденциальности</p>
          </div>
        </div>
      </div>
    </div>
    <SendRequestMobile :openSr="openSr" @closeSr="openSr = false" />
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Robot from "@/assets/Robot.png";
// import laptop from "@/assets/laptop.png";
import BaseIcon from "@/components/BaseIcon.vue";
import visual from "@/assets/visual.png";
import BaseServices from "@/components/BaseServices.vue";
import adv from "@/assets/adv/adv.png";
import adv1 from "@/assets/adv/adv1.png";
import adv2 from "@/assets/adv/adv2.png";
import adv3 from "@/assets/adv/adv3.png";
import adv4 from "@/assets/adv/adv4.png";
import adv5 from "@/assets/adv/adv5.png";
import jumis from "@/assets/partners/jumis.png";
import rpa from "@/assets/partners/rpa.png";

import BaseFAQ from "@/components/BaseFAQ.vue";
import SendRequestModal from "@/components/Modal/SendRequestModal.vue";
import SendRequestMobile from "@/components/SendRequestMobile.vue";

export default {
  components: {
    BaseServices,
    SendRequestModal,
    BaseHeader,
    BaseButton,
    BaseIcon,
    BaseFAQ,
    SendRequestMobile,
  },
  data() {
    return {
      openSr: false,
      thisIsMobile: false,
      openModal: false,
      imgRobot: Robot,
      laptop: visual,
      jumis: jumis,
      rpa: rpa,
      advs: [
        "Оптимизировать время на выполнение задач",
        "Снизить ошибки",
        "Исключение человеческого фактора",
        "Персонализация услуг",
        "Снизить затраты",
        "Улучшить качества",
        "Повышение конкурентоспособности",
        "Улучшение аналитики",
        "Автоматизировать отчеты",
      ],
      advantages: [
        {
          title: "Работа 24/7",
          subtitle:
            "Робот работает 24 часа в сутки, не устает и не отвлекается",
          img: adv,
        },
        {
          title: "Надежность",
          subtitle: "Правильно настроенный робот не допустит ошибок",
          img: adv3,
        },

        {
          title: "Быстрое внедрение",
          subtitle:
            "Внедрение роботов занимает от 2 до 8 недель в зависимости от сложности процесса",
          img: adv1,
        },
        {
          title: "Производительность",
          subtitle: "Робот влияет на бизнес, повышая скорость процессов на 30%",
          img: adv4,
        },
        {
          title: "Стоимость",
          subtitle: "С роботом не нужно делать дорогостоящие доработки систем",
          img: adv2,
        },

        {
          title: "Адаптивность",
          subtitle:
            "Роботы легко интегрируются с любыми системами и приложениями",
          img: adv5,
        },
      ],
      percentage: 0,
      savings: 0,
      enterprises: 0,
    };
  },

  computed: {
    percent() {
      return this.percentage.toFixed();
    },
    saving() {
      return this.savings.toFixed();
    },
    enterprise() {
      return this.enterprises.toFixed();
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    openSrModal() {
      this.thisIsMobile = false;
      this.openSr = true;
    },

    openBots() {
      this.$router.push("/AllBots");
    },
    openModalWhenClick() {
      console.log("open");
      this.openModal = true;
    },
    openMobileMenu(mobileMenu) {
      console.log(mobileMenu);
      this.thisIsMobile = !this.thisIsMobile;
    },
    scrollToEl(item) {
      this.$refs[item.ref].scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      var intval = setInterval(() => {
        if (this.percentage < 95) this.percentage += 5;
        else clearInterval(intval);
      }, 30);
      var intval2 = setInterval(() => {
        if (this.savings < 10) this.savings += 1;
        else clearInterval(intval2);
      }, 30);
      var intval3 = setInterval(() => {
        if (this.enterprises < 20) this.enterprises += 1;
        else clearInterval(intval3);
      }, 30);
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  color: #15114e;
  margin: 0;
}
.home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f7ff;
  border-radius: 80px;
  height: 640px;
  padding-left: 48px;
  padding-right: 48px;
  position: relative;

  &__left {
    width: 600px;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #031f42;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  &__right {
    position: relative;
    &__icon {
      position: absolute;
      width: 60px;
      height: 60px;
      background: #44b710;
      box-shadow: 5px 10px 25px rgba(249, 178, 39, 0.15);
      border-radius: 100px;
      top: 69%;
      left: 82%;
      svg {
        position: absolute;
        top: 22%;
        left: 31%;
      }
    }
    &__icon_2 {
      position: absolute;
      width: 38px;
      height: 38px;
      background: #7b8cde;
      box-shadow: 2.5px 5px 12.5px rgba(249, 178, 39, 0.15);
      border-radius: 100px;
      top: 86%;
      left: 76%;
      svg {
        position: absolute;
        top: 20%;
        left: 26%;
      }
    }
    &__icon_3 {
      position: absolute;
      top: 93%;
      left: 66%;
    }
  }
  &__right__mobile {
    display: none;
  }

  &__info {
    background: #ffffff;
    border-radius: 80px;
    padding: 56px 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    margin-top: -5%;

    &__text {
      margin: 0;
      margin-top: 16px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #737373;
    }

    &__icons {
      position: relative;
      background: #e5f1fb;
      width: 36px;
      height: 36px;
      border-radius: 100px;
    }

    &__icon {
      position: absolute;
      top: 21%;
      left: 24%;
    }

    &__icon-2 {
      position: absolute;
      top: 21%;
      left: 24%;
    }

    &__left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &__numbers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: #131927;
          margin: 0;
          margin-right: 16px;
        }
      }
    }

    &__right {
      margin-left: 72px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &__numbers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: #131927;
          margin: 0;
          margin-right: 16px;
        }
      }
    }
  }

  &__adv {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
      p {
        margin: 0;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-transform: uppercase;
        color: #555f6f;
      }
    }

    &__cards {
      background: #ffffff;
      padding: 16px;
      border: 1px solid #dddeea;
      border-radius: 12px;
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 8px;
        background: rgba(68, 183, 16, 0.3);
        border-radius: 8px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #15114e;
        margin: 0;
        white-space: nowrap;
      }
    }
  }
  &__advs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    width: 100%;
    margin-top: 40px;
  }
}
.info {
  margin-top: 80px;
  background: #f9f9f9;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    &-icon {
      position: absolute;
      top: -6%;
      right: -5%;
    }
    &-icon_1 {
      position: absolute;
      top: 5%;
      right: -6%;
    }
  }
  &__right {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 108px;
    &__subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #555f6f;
      margin: 0;
      margin-top: 12px;
      margin-bottom: 32px;
    }
  }
}
.robot {
  width: 389px;
  height: 389px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.laptop {
  width: 560px;
  height: 403px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.bots {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &__title {
    width: 100%;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-transform: uppercase;
    color: #555f6f;
    margin: 0;
    margin-bottom: 8px;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.advantages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      text-transform: uppercase;
      color: #555f6f;
      margin: 0;
      margin-bottom: 8px;
    }
  }

  &__points {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
    grid-row-gap: 40px;
    margin-top: 40px;
  }
  &__point {
    display: flex;
    align-items: center;

    &__titles {
      margin-left: 40px;
      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        margin-bottom: 8px;
        color: #15114e;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        margin: 0;
        color: #555f6f;
      }
    }
    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}
.numbers {
  padding-top: 40px;
  padding-bottom: 130px;
  display: flex;
  background: linear-gradient(70.58deg, #0070d7 10.65%, #87c5ff 95%);
  flex-direction: column;
  align-items: center;
  position: relative;
  h2 {
    color: #f8f9fa;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: #f8f9fa;
      margin: 0;
      margin-top: 24px;
    }
  }
  &__progress {
    background: #fefefe;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 32px 48px;
    display: grid;
    // align-items: center;
    // justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    width: 83%;
    position: absolute;
    top: 74%;

    h3 {
      font-weight: 700;
      font-size: 40px;
      line-height: 110%;
      text-align: center;
      color: #15114e;
      margin: 0;
    }

    &__sub {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #555f6f;
      margin: 0;
      margin-top: 12px;
    }
  }
}
.pre-footer {
  background: #0070d7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 48px 0;
  margin-bottom: 80px;

  h2 {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    margin: 0;
    /* or 38px */
    margin-bottom: 24px;

    letter-spacing: -0.02em;
  }
}
.footer {
  background: #f9f9f9;
  padding: 24px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #555f6f;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #0070d7;
      margin: 0;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
.robot-mobile {
  display: none;
}
.desktop {
  display: inline;
}
.mobile {
  display: none;
}
.partner-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rpa {
    width: 187px;
    height: 65px;
  }
  .jumis {
    width: 124px;
    height: 124px;
  }
}
.partner {
  &__title {
    display: flex;
    flex-direction: column;
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      margin-bottom: 8px;
    }
    h2 {
      margin-bottom: 47px;
    }
  }
}
.client {
  display: flex;
  flex-direction: column;
  &__title {
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      margin-bottom: 8px;
    }
    h2 {
      margin-bottom: 16px;
    }
  }
}

//multiselect
:deep(.multiselect) {
  margin-bottom: 12px !important;
  border-radius: 12px !important;
  width: 98% !important;
}
:deep(.multiselect-option.is-selected) {
  background: #0070d7 !important;
}
:deep(.multiselect-option) {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #555f6f !important;
}
:deep(.multiselect.is-active) {
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #00aaffa6) !important;
}
:deep(.multiselect-single-label-text) {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

:deep(.dp__input) {
  font-size: 16px;
}
:deep(.btn) {
  height: 46px;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline;
  }
  .white {
    background: #fff;
  }
  h1 {
    margin-top: 24px;
  }
  h2 {
    font-size: 24px;
    line-height: 120%;
    text-align: center;
  }
  .home {
    border-radius: 40px;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    height: 622px;

    justify-content: flex-start;

    &__adv {
      &__title {
        p {
          font-size: 12px;
        }
      }

      &__cards {
        padding: 12px;

        // width: 328px;

        &-icon {
          margin-right: 12px;
          width: 24px;
          height: 24px;
        }
        &-title {
          font-size: 14px;
          white-space: initial;
        }
      }
    }

    &__advs {
      grid-template-columns: none;
      grid-column-gap: none;
      grid-row-gap: 12px;
      margin-top: 20px;
    }
    &__left {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        /* or 17px */

        text-align: center;

        /* black */

        color: #15114e;
        margin-top: 24px;
        margin-bottom: 32px;
      }
    }
    &__right {
      display: none;
    }
    &__right__mobile {
      display: flex;
      align-items: center;
      margin-top: 40px;
    }
    &__info {
      flex-direction: column;
      padding: 24px 16px;
      // width: 296px;

      &__text {
        font-size: 16px;
        line-height: 24px;
      }
      &__left {
        &__numbers {
          &_title {
            font-weight: 700;
            font-size: 28px;
            line-height: 40px;
          }
        }
      }

      &__right {
        margin-left: 0;
        margin-top: 24px;
        &__numbers {
          &_title {
            font-weight: 700;
            font-size: 28px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .robot-mobile {
    display: flex;
    width: 198.3px;
    height: 201.2px;
  }
  .info {
    margin-top: 48px;
    background: #fff;
    &__container {
      flex-direction: column;
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      &-icon {
        position: absolute;
        top: -6%;
        right: -5%;
      }
      &-icon_1 {
        position: absolute;
        top: 5%;
        right: -6%;
      }
    }
    &__right {
      // display: flex;
      align-items: center;
      // flex-direction: column;
      // justify-content: center;
      margin-left: 0;
      margin-top: 40px;
      &__subtitle {
        font-size: 16px;
        text-align: center;

        color: #555f6f;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 24px;
      }
    }
  }
  .laptop {
    width: 328px;
    height: 236px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .advantages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        text-transform: uppercase;
        color: #555f6f;
        margin: 0;
        margin-bottom: 8px;
      }
    }

    &__points {
      display: grid;
      grid-template-columns: none;
      grid-column-gap: unset;
      grid-row-gap: 28px;
      margin-top: 20px;
    }
    &__point {
      display: flex;
      align-items: center;

      &__titles {
        margin-left: 16px;
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #15114e;
          margin: 0;
          margin-bottom: 8px;
        }
        p {
        }
      }
      &__icon {
        width: 40px;
        height: 40px;
      }
    }
  }
  .partner-client {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:column;
  .rpa {
    width: 187px;
    height: 65px;
  }
  .jumis {
    width: 124px;
    height: 124px;
  }
}
.partner {
  margin-bottom: 8px;
  &__title {
    display: flex;
    flex-direction: column;
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      margin-bottom: 8px;
    }
    h2 {
      margin-bottom: 16px;
    }
  }
}
.client {
  display: flex;
  flex-direction: column;
  &__title {
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      margin-bottom: 8px;
    }
    h2 {
      margin-bottom: 16px;
    }
  }
}
  .numbers {
    padding-top: 2px;
    padding-bottom: 70px;
    display: flex;
    background: linear-gradient(70.58deg, #0070d7 10.65%, #87c5ff 95%);
    flex-direction: column;
    align-items: center;
    position: relative;
    h2 {
      color: #f8f9fa;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        margin: 0;
        margin-top: 16px;
      }
    }
    &__progress {
      background: #fefefe;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      padding: 24px 8px;
      display: grid;
      // align-items: center;
      // justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
      width: 83%;
      position: absolute;
      top: 74%;

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        text-align: center;
        color: #15114e;
        margin: 0;
      }

      &__sub {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #555f6f;
        margin: 0;
        margin-top: 12px;
      }
    }
  }
  .pre-footer {
    padding: 40px 24px;
    margin-bottom: 80px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
    }
  }
  .footer {
    background: #f9f9f9;
    padding: 0;

    &__content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__left {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #555f6f;
    }
    &__right {
      margin-top: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #0070d7;
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
