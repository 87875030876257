<template>
  <div class="faq container">
    <h2>FAQ</h2>
    <div class="faq__points">
      <div class="faq__point__top equal-columns">
        <div class="faq__point" @click="openFAQ1 = !openFAQ1">
          <div class="faq__point__title">
            <h3>Что такое RPA?</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ1 ? 'rotate' : ''"
            />
          </div>

          <slide-up-down v-model="openFAQ1" :duration="700">
            <p>
              RPA (Robotic Process Automation) - это использование компьютерных
              программ для автоматизации повторяющихся и рутинных задач, которые
              обычно выполняют люди. Например, RPA может помочь автоматизировать
              процесс заполнения форм, обновления баз данных, отправки сообщений
              на электронную почту, обработки заказов и других задач. Это
              помогает сократить время и затраты на ручную работу, снизить
              количество ошибок и повысить эффективность бизнес-процессов.
            </p>
          </slide-up-down>
        </div>
        <div class="faq__point" @click="openFAQ2 = !openFAQ2">
          <div class="faq__point__title">
            <h3>Хочу роботизацию</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ2 ? 'rotate' : ''"
            />
          </div>
          <slide-up-down v-model="openFAQ2" :duration="700">
            <p>
              Вы можете написать нам на адрес
              <a href="mailto:info@skymetrics.kz">info@skymetrics.kz</a>, и мы
              обязательно свяжемся с вами.
            </p>
          </slide-up-down>
        </div>
      </div>

      <div class="faq__point__top equal-columns">
        <div class="faq__point" @click="openFAQ3 = !openFAQ3">
          <div class="faq__point__title">
            <h3>Я не понимаю, что можно роботизировать</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ3 ? 'rotate' : ''"
            />
          </div>
          <slide-up-down v-model="openFAQ3" :duration="700">
            <p>
              Вы имеете возможность обратиться к нам по адресу
              <a href="mailto:info@skymetrics.kz">info@skymetrics.kz</a>, и мы с
              радостью предоставим вам нашу экспертную помощь в выборе
              процессов, а также поделимся ценными рекомендациями относительно
              наилучшего старта вашего пути в области роботизации.
            </p>
          </slide-up-down>
        </div>
        <div class="faq__point" @click="openFAQ4 = !openFAQ4">
          <div class="faq__point__title">
            <h3>Что может Skymetrics?</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ4 ? 'rotate' : ''"
            />
          </div>
          <slide-up-down v-model="openFAQ4" :duration="700">
            <p>
              Компания SkyMetrics занимается автоматизацией бизнес-процессов с
              помощью технологий RPA (Robotic Process Automation) и AI
              (Artificial Intelligence). Она помогает компаниям оптимизировать
              свои бизнес-процессы и повысить эффективность своей работы,
              используя программные роботы и алгоритмы машинного обучения.
              SkyMetrics предоставляет своим клиентам комплексное решение,
              включающее в себя анализ и оптимизацию бизнес-процессов, выбор
              подходящих инструментов и технологий, разработку и внедрение
              программных роботов, а также обучение и поддержку персонала.
              Компания специализируется на автоматизации различных отраслей,
              таких как финансы, логистика, здравоохранение, телекоммуникации и
              другие.
            </p>
          </slide-up-down>
        </div>
      </div>

      <div class="faq__point__top equal-columns">
        <div class="faq__point" @click="openFAQ5 = !openFAQ5">
          <div class="faq__point__title">
            <h3>Хочу пилот, куда мне обратиться?</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ5 ? 'rotate' : ''"
            />
          </div>
          <slide-up-down v-model="openFAQ5" :duration="700">
            <p>
              Вы можете написать нам на адрес
              <a href="mailto:info@skymetrics.kz">info@skymetrics.kz</a>, и мы
              обязательно свяжемся с вами.
            </p>
          </slide-up-down>
        </div>
        <div class="faq__point" @click="openFAQ6 = !openFAQ6">
          <div class="faq__point__title">
            <h3>Хочу посмотреть реальные кейсы</h3>
            <base-icon
              name="arrow"
              :height="24"
              :width="24"
              :class="openFAQ6 ? 'rotate' : ''"
            />
          </div>
          <slide-up-down v-model="openFAQ6" :duration="700">
            <p>
              Просто отправьте нам письмо на адрес
              <a href="mailto:info@skymetrics.kz">info@skymetrics.kz</a>, и мы с
              радостью продемонстрируем и расскажем вам все подробности.
            </p>
          </slide-up-down>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue";
import SlideUpDown from "vue3-slide-up-down";

export default {
  components: { BaseIcon, SlideUpDown },
  data() {
    return {
      openFAQ1: false,
      openFAQ2: false,
      openFAQ3: false,
      openFAQ4: false,
      openFAQ5: false,
      openFAQ6: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.faq {
  margin-top: 80px;
  transition: all 1s ease;
  margin-bottom: 80px;

  &__points {
    display: flex;
    flex-direction: column;
  }
  &__point {
    padding: 24px;
    border: 1px solid #dddeea;
    border-radius: 12px;
    cursor: pointer;
    &:first-child {
      margin-right: 32px;
    }
    &__top {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  color: #15114e;
  margin: 0;
  margin-bottom: 40px;
}
h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15114e;
  margin: 0;
  white-space: nowrap;
}
svg {
  cursor: pointer;
}
.rotate {
  transition: all 0.5s ease;
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}
p {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* or 22px */

  /* gray - 400 */

  color: #555f6f;
  margin: 0;
  margin-top: 16px;
}
.equal-columns > * {
  flex: 1;
}
@media only screen and (max-width: 600px) {
  .faq {
   margin-top: 110px;
    transition: all 1s ease;
    margin-bottom: 80px;

    &__points {
      display: flex;
      flex-direction: column;
    }
    &__point {
      padding: 12px 16px;
      border: 1px solid #dddeea;
      border-radius: 12px;
      cursor: pointer;
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
      &__top {
        display: flex;
        align-items: flex-start;
        flex-direction:column;
        margin-top:20px;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #15114e;
    margin: 0;
    // margin-bottom: 40px;
  }
  h3 {
   font-weight: 600;
font-size: 16px;
line-height: 24px;
    color: #15114e;
    margin: 0;
       white-space: normal;
  }
  svg {
    cursor: pointer;
  }
  .rotate {
    transition: all 0.5s ease;
    transform: rotate(180deg); /* Equal to rotateZ(45deg) */
  }
  p {
   font-weight: 400;
font-size: 12px;
line-height: 18px;

    /* gray - 400 */

    color: #555f6f;
    margin: 0;
    margin-top: 8px;
  }
  .equal-columns > * {
    flex: 1;
  }
}
</style>
