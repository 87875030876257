<template>
  <div>
    <div class="container" v-if="!openSr">
      <div class="header">
        <base-icon
          name="logo"
          :height="24"
          :width="120"
          @click="toMainPage"
          class="main-icon"
        />
        <base-icon
          name="logo"
          :height="20"
          :width="100"
          @click="toMainPage"
          class="main-icon-mobile"
        />
        <base-icon
          :name="mobileMenu ? 'close' : 'menu'"
          :height="24"
          :width="24"
          @click="openHamburger"
          class="menu-icon-mobile"
        />

        <div class="nav">
          <p v-for="item in navBar" :key="item.id" class="nav__item" @click="$emit('scrollToEl', item)"  >
            {{ item.title }}
          </p>
        </div>
        <div class="buttons">
          <div class="lang">
            <!-- <p v-for="lang in langs" :key="lang.id">{{lang.title}}</p> -->
            <p>Рус</p>
            <base-icon name="arrow" :height="16" :width="16" />
          </div>
          <!-- <base-button variant="outlined">Заказать консультацию</base-button> -->
          <SendRequestModal :openModal="openModal" outlined />
        </div>
      </div>
      <transition name="fade">
        <div :class="mobileMenu ? 'open-menu' : 'closed-menu'">
          <p v-for="item in navBar" :key="item.id">
            {{ item.title }}
          </p>
          <p class="nav__item">Рус</p>
          <SendRequestModal :openModal="openModal" outlined class="desktop" />
          <base-button
            @onBtnClick="openSr = true"
            variant="outlined"
            class="mobile"
            >Заказать консультацию</base-button
          >
        </div></transition
      >
    </div>
    <SendRequestMobile :openSr="openSr" @closeSr="openSr = false" />
  </div>
</template>
<script>
// import BaseButton from "@/components/BaseButton.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import SendRequestModal from "@/components/Modal/SendRequestModal.vue";
import BaseButton from "@/components/BaseButton.vue";
import SendRequestMobile from "@/components/SendRequestMobile.vue";

export default {
  components: {
    SendRequestModal,
    BaseIcon,
    BaseButton,
    SendRequestMobile,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSr: false,
      navBar: [
        {
          title: "Что может бот",
          id: 1,
          ref: "whatBotCanDo"
        },
        {
          title: "Сервисы",
          id: 2,
          ref: "services"   
           },
        {
          title: "FAQ",
          id: 3,
          ref: "faq"
        
        },
      ],
      langs: [
        {
          title: "Рус",
          id: 1,
        },
        {
          title: "Каз",
          id: 2,
        },
        {
          title: "Eng",
          id: 3,
        },
      ],
      mobileMenu: false,
    };
  },
  methods: {
    toMainPage() {
      this.$router.push("/");
    },
    openHamburger() {
      this.mobileMenu = !this.mobileMenu;
      this.$emit("openHamburger", this.mobileMenu);
    },
  
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav {
  display: flex;
  align-items: center;
  &__item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 32px;
    cursor: pointer;
  }
}
.buttons {
  display: flex;
  align-items: center;
}
.lang {
  display: flex;
  align-items: center;
  margin-right: 24px;
  svg {
    cursor: pointer;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 4px;
  }
}
.main-icon {
  cursor: pointer;
}
.main-icon-mobile {
  display: none;
}
.menu-icon-mobile {
  display: none;
}
.nav-mobile {
  display: none;
}
// .open-menu {
//   display: none;
// }
.closed-menu {
  display: none;
}
.desktop {
  display: inline;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline;
  }
  .nav {
    display: none;
  }
  .buttons {
    display: none;
  }
  .main-icon-mobile {
    display: flex;
  }
  .main-icon {
    display: none;
  }
  .menu-icon-mobile {
    display: flex;
    cursor: pointer;
  }
  .nav-mobile {
    background: #fff;
    width: 100%;
    height: 100%;
  }
  .open-menu {
    opacity: 1;
    height: 150px;
    margin-top: 24px;
  }
  .closed-menu {
    display: none;
  }
  .nav__item {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    margin: 0;
    margin-bottom: 32px;
    /* black */

    color: #15114e;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
}
</style>
