import Client from './AxiosClient';


export default {
    get(resource) {
        return Client.get(`${resource}`);
    },
    create(resource, payload) {
        return Client.put(`${resource}`, payload);
    },
    update(resource, payload) {
        return Client.put(`${resource}`, payload);
    },
    delete(resource) {
        return Client.delete(`${resource}`)
    },
    post(resource, payload) {
        return Client.post(`${resource}`, payload)
    }
};