const botList = [{
        ids: "1",
        icon: "BOT-ICON-1",
        title: "WhatsApp bot",
        category: "Conversation",
        subtitle: "Scrapping news from different sources",
        problem: "Ежедневно к клиенту обращается 200+ пользователей, ежемесячное количество пользователей доходило до 8000 людей. В процесс были вовлечены 6 человек из колл-центра, работа занимала все 8 рабочих часов.",
        solution: ['Систематизировали данные, автоматизировали ежедневную выгрузку в базу данных', 'Систематизировали данные, автоматизировали ежедневную выгрузку в базу данных'],
        result: ['Автоматизирован процесс ответа', 'Устранение человеческого фактора'],
        feature: {
            instrument: 'Python',
            time: 'От 2-х недель',
            difficulty: 'medium'
        }
    },
    {
        ids: "2",
        icon: "BOT-ICON-1",
        title: "Автоматизация отчетности компании",
        category: "Business Intelligence",
        subtitle: "Scrapping news from different sources",
        desc: 'Услуги автоматизации отчетов в Power BI позволяют клиентам значительно улучшить свои бизнес-процессы и принятие решений на основе аналитических данных. С помощью автоматизации отчетности, клиенты получают следующие преимущества:',
        services: ['Эффективность и время: Автоматизация процессов сбора, обработки и анализа данных позволяет клиентам сэкономить время и ресурсы, которые раньше были затрачены на ручную обработку и подготовку отчетов. Они могут быстро получать актуальные данные и отчеты, что помогает им принимать оперативные и информированные решения.',
            'Точность и надежность: Автоматизированный процесс обработки данных минимизирует возможность человеческой ошибки, что гарантирует высокую точность и надежность отчетов. Клиенты могут быть уверены в том, что предоставляемая им информация является аккуратной и достоверной.',
            'Легкий доступ к данным: Создание единого дата-лейка и автоматизация импорта данных из различных источников обеспечивает клиентам удобный и легкий доступ к своим данным. Они могут быстро извлекать нужную информацию, проводить анализ и генерировать отчеты в удобном формате.'
        ],
        end: 'Общий результат услуг автоматизации отчетов в Power BI - повышение эффективности, точности и оперативности бизнес-аналитики клиента, что позволяет им принимать более обоснованные и стратегические решения для развития своего бизнеса.',
        feature: {
            instrument: 'PowerBI',
            time: 'От 2-х недель',
            difficulty: 'medium'
        }
    },
    {
        ids: "3",
        icon: "BOT-ICON-1",
        title: "Telegram bot",
        category: "Conversation",
        subtitle: "Scrapping news from different sources",
        problem: "Клиент предоставляет аутсорс сотрудников для компаний. Сотрудники ежедневно отписывались в журнал со временем прихода и ухода. Время было невозможно отследить, в процесс вовлекались 2 сотрудников службы безопасности для подтверждения времени их реального прихода и ухода. Задержание зарплаты, пока компании подтвердят записи журнала. Утеря бумажных носителей документов во время транспортировки.",
        solutions: "Сравнение местоположения сотрудника и здания компании. В случае прохождения верификации согласно запросу клиента (нахождение в радиусе 500 метров от здания) - запись уходит в базу данных. Создание единой административной панели для удобства подтверждения оплаты.",
        result: ['Автоматизирован процесс оплаты зарплат неподтвержденных сотрудников', 'Исключена возможность утери документов', 'Ускорили сроки выплат зарплат '],
        feature: {
            instrument: 'Python',
            time: 'От 2-х недель',
            difficulty: 'medium'
        }
    },
    {
        ids: "4",
        icon: "BOT-ICON-1",
        title: "SCD test",
        category: "Medicine",
        subtitle: "Scrapping news from different sources",
        desc: 'SCD AI является онлайн инструментом, который способен определить индекс фрагментации ДНК сперматозоидов, анализируя фотоматериалы, полученные микроскопа после проведения теста на дисперсию хроматина сперматозоидов (Sperm Chromation Disperstion Test, SCDtest)',

        feature: {
            instrument: 'Python',
            time: 'От 3-х месяцев',
            difficulty: 'hard'
        }

    },
    {
        ids: "5",
        icon: "BOT-ICON-1",
        title: "Scrapper",
        category: 'Продажи "Бизнес для бизнеса"',
        subtitle: "Scrapping news from different sources",
        problem: 'Технология получения веб-данных путем извлечения их со страниц веб-ресурсов. Скрапперы широко используются для различных целей, включая сбор данных для исследований, мониторинга конкурентов, анализа рынка, создания баз данных, автоматического заполнения форм и многого другого. Они могут обрабатывать большие объемы информации с разных веб-сайтов или ресурсов, что позволяет получать ценную информацию и проводить аналитику.',
        feature: {
            instrument: 'UiPath, Python',
            time: '5-7 рабочих дней',
            difficulty: 'easy'
        }
    },
    {
        ids: "6",
        icon: "BOT-ICON-1",
        title: "Сверка Электронных счет-фактур",
        category: "Бухгалтерия и налоги",
        subtitle: "Scrapping news from different sources",
        feature: {
            instrument: 'UiPath, Python',
            time: '5-7 рабочих дней',
            difficulty: 'easy'
        }
    },
    {
        ids: "7",
        icon: "BOT-ICON-1",
        title: "Сверка данных из различных источников",
        category: "Документооборот",
        subtitle: "Scrapping news from different sources",
        desc: 'Наш инновационный бот предлагает вам решение для автоматизации проверки и сопоставления информации из различных источников. Забудьте о трудоемкой ручной сверке и доверьтесь нашему боту, который справится с этим заданием в разы быстрее и точнее.',
        question: 'Почему наш бот - лучший выбор для вас?',
        adv: '✔️ Эффективность и точность: Наш бот осуществляет мгновенную и точную сверку данных из множества источников, предотвращая ошибки и исключая несоответствия. Ваши процессы станут гораздо более эффективными и надежными благодаря автоматизации этого рутинного задания.',
        feature: {
            instrument: 'UiPath',
            time: 'От 3-х дней',
            difficulty: 'easy'
        }
    },
    {
        ids: "8",
        icon: "BOT-ICON-1",
        title: "Почтовые помощники",
        category: "Отдел качества сервиса",
        subtitle: "Scrapping news from different sources",
        feature: {
            instrument: 'UiPath',
            time: 'От 5 дней',
            difficulty: 'easy'
        }
    },
    {
        ids: "9",
        icon: "BOT-ICON-1",
        title: "HR AssistBot",
        category: "HR",
        subtitle: "Scrapping news from different sources",
        feature: {
            instrument: 'UiPath',
            time: 'От 3-х дней',
            difficulty: 'easy'
        }
    },
]

export default {
    botList
}