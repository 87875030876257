<template>
  <div class="wrapper">
    <base-button
      @onBtnClick="openModal = true"
      variant="outlined"
      v-if="outlined"
      >Заказать консультацию</base-button
    >
    <base-button @onBtnClick="openModal = true" v-if="!outlined && !footer"
      >Заказать консультацию</base-button
    >
    <base-button @onBtnClick="openModal = true" variant="footer" v-if="footer"
      >Заказать консультацию</base-button
    >

    <Modal
      v-model:visible="openModal"
      :title="''"
      :closable="false"
      :okButton="send"
    >
      <h3 class="modal__title">Заполните форму и мы с вами свяжемся:</h3>
      <base-icon
        name="close"
        width="32"
        height="32"
        class="icon"
        @click="openModal = false"
      />
      <div class="inputs">
        <div class="input">
          <p>Название вашей компаний *</p>
          <input
            placeholder="Введите название"
            v-model="nameOrg"
            :class="{ 'error-input': v$.nameOrg.$error }"
          />
          <div v-if="v$.nameOrg.$error" class="error">Обязательное поле</div>
        </div>
        <div class="input">
          <p>Сфера бизнеса *</p>
          
        <MultiSelect
          :options="busFields"
          v-model="busField"
          :class="{ 'error-input': v$.nameOrg.$error }"
        />

          
          <div v-if="v$.busField.$error" class="error">Обязательное поле</div>
        </div>
        <div class="input">
          <p>Размер компании</p>
        <MultiSelect :options="companySizes" v-model="companySize" />
        </div>
        <div class="input">
          <p>Ваш номер *</p>
        <input placeholder="+7 (7**) *** ** **" v-model="phoneNumber" />
        </div>

        <div class="input">
          <p>Вашa почта</p>
          <input
            placeholder="Введите почту"
            v-model="email"
            :class="{ 'error-input': v$.nameOrg.$error }"
          />
          <div v-if="v$.email.$error" class="error">Обязательное поле</div>
        </div>
        <base-button @onBtnClick="submitForm" class="send-btn"
          >Оставить заявку</base-button
        >
      </div>
      <p class="bottom-text">
        Оставляя данные на сайте, Вы соглашаетесь с
        <a>Политикой конфиденциальности</a>
      </p>
    </Modal>
    <Modal
      v-model:visible="acceptedModal"
      :title="''"
      :closable="false"
      :okButton="send"
    >
      <base-icon
        name="close"
        width="32"
        height="32"
        class="icon"
        @click="acceptedModal = false"
      />
      <div class="accept-modal">
        <base-icon name="modal_icon" width="64" height="64" />
        <h2>Спасибо, Ваша заявка принята!</h2>
        <p>Мы свяжемся с Вами в ближайшее время.</p>
      </div>
    </Modal>
  </div>
</template>

<script>
// import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { Modal } from "usemodal-vue3";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import "@vueform/multiselect/themes/default.css";
import MultiSelect from "@vueform/multiselect";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Modal, BaseButton, BaseIcon, MultiSelect },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
  // setup() {
  //   return { v$: useVuelidate() };
  // },
  data() {
    return {
      v$: useValidate(),
      acceptedModal: false,
      openModal: this.isVisible,
      nameOrg: "",
     
      phoneNumber: "",
      email: "",
        busField: "",
      busFields: [
        "Продажа B2B",
        "Отдела качество сервиса",
        "Бухгалтерия и налоги",
        "Клиентский сервис",
        "Закупки",
        "HR",
        "IT",
      ],
         companySize: "",
      companySizes: [
        "Малое предприятие",
        "Среднее предприятие",
        "Крупное предприятие",
      ],
    };
  },
  validations() {
    return {
      nameOrg: { required },
      busField: { required },
      email: { required, email }, // Matches this.contact.email
    };
  },
  computed: {
      ...mapGetters({
      getRequestResponse: "getRequestResponse",
    }),
  },
  methods: {
      ...mapActions({
      sendRequest: "sendRequest",
    }),
    submitForm() {
      this.v$.$validate(); // checks all inputs

      if (!this.v$.$error) {
        // if ANY fail validation
          let payload = {
          company_name: this.nameOrg,
          business_area: this.busField,
          company_size: this.companySize,
          phone_number: this.phoneNumber,
          email: this.email,
          request_is_processed: "on",
          comment: "test",
        };
        this.sendRequest(payload);
        this.openModal = false;
        this.acceptedModal = true;
        console.log("fd ");
      } else {
        console.log("fd ");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;

  text-align: center;

  color: #15114e;
  margin: 0;
  margin-bottom: 16px;
  margin-top: 32px;
}

.modal {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;

    text-align: center;

    color: #15114e;
    margin: 0;
    // margin-top: 40px;
  }
}
.input {
  margin-top: 20px;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* gray - 400 */

    color: #555f6f;

    margin: 0;
    margin-bottom: 4px;
  }
}
.inputs {
  margin-top: 24px;
}

.send-btn {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 28px;
}
.bottom-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #b0bdc6;
  margin: 0;
}
a {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin: 0;
  color: #0070d7;
}
.send-reques-modal {
  position: relative;
}
.icon {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
.error {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff3346;
  margin: 0;
  margin-top: 4px;
}
.error-input {
  border: 1px solid #ff3346;
}
.accept-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;

    /* gray - 400 */

    color: #555f6f;
    margin: 0;
    margin-bottom: 32px;
  }
}
:deep(.modal-vue3-content) {
  background: #ffffff;
  border-radius: 40px !important;
  padding: 64px 94px 24px 94px;
  top: 12px !important;
  // width: 628px !important;
  // margin: auto auto !important;
}
:deep(.modal-vue3-footer-cancel) {
  display: none !important;
}
:deep(.modal-vue3-header) {
  border: none !important;
  display: none !important;
}
:deep(.modal-vue3-footer) {
  border: none !important;
  display: none !important;
}
:deep(.modal-vue3-body) {
  padding: 0 !important;
  overflow: auto !important;
  // height: 427px !important;
}
:deep(.modal-vue3-footer-ok) {
  display: none !important;
}
//multiselect
:deep(.multiselect) {
  margin-bottom: 12px !important;
  border-radius: 12px !important;
  width: 98% !important;
}
:deep(.multiselect-option.is-selected) {
  background: #0070d7 !important;
}
:deep(.multiselect-option) {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #555f6f !important;
}
:deep(.multiselect.is-active) {
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #00aaffa6) !important;
}
:deep(.multiselect-single-label-text) {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

:deep(.dp__input) {
  font-size: 16px;
}
:deep(.btn) {
  height: 46px;
}

.mobile {
  display: none;
}
.mobile-btn {
  display: none;
}

</style>
